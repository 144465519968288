<template>
  <el-dialog
    :title="displayText.title"
    :visible="true"
    width="600px"
    :close-on-click-modal="false"
    @close="$emit('close')"
  >
    <GrayBlockContainer>
      <div class="flex flex-col gap-[20px]">
        <div v-for="item in planData" :key="item.id" class="flex flex-col gap-[4px]">
          <span class="text-gray-100 font-bold text-normal">{{ item.name }}</span>
          <div v-for="subItem in item.items" :key="subItem.id" class="flex gap-[20px] text-gray-100 text-sub leading-[20.27px]">
            <span class="font-medium w-[120px]">{{ subItem.label }}</span>
            <p
              v-if="subItem.link"
              class="underline ml-[20px] font-medium text-primary-100 cursor-pointer whitespace-pre-line"
              @click="$router.push(subItem.link)"
            >
              {{ subItem.value }}
            </p>
            <span v-else class="font-normal ml-[20px] whitespace-pre-line">{{ subItem.value }}</span>
          </div>
        </div>
      </div>
    </GrayBlockContainer>
    <span slot="footer" class="dialog-footer">
      <div class>
        <BaseElButton type="primary" @click="$emit('close')">
          {{ displayText.btn.confirm }}
        </BaseElButton>
      </div>
    </span>
  </el-dialog>
</template>

<script>
import { defineComponent, computed, onMounted, ref, reactive, onBeforeMount } from 'vue'
import { get, map, join, reduce } from 'lodash'
import GrayBlockContainer from '@/components/Container/GrayBlockContainer.vue'
import { subscribePaymentDependTypeConfig, subscribePaymentTimeTypeConfig } from '@/config/subscribe'
import { FindPeriodicBenefitTemplate } from '@/api/periodicBenefit'
import { useShop } from '@/use/shop'
import { usePeriodBenefitTemplate } from '@/use/usePeriodBenefit'

export default defineComponent({
  name: 'SubscribePlanDialog',
  components: {
    GrayBlockContainer,
  },
  props: {
    plan: {
      type: Object,
      default: () => ({}),
    },
  },
  setup (props, { emit }) {
    const { shopId } = useShop()
    const { getFormatEntitlementList } = usePeriodBenefitTemplate()
    const equityPack = reactive({
      type: null,
      data: null,
    })
    const displayText = computed(() => {
      const data = {
        title: '訂閱方案詳情',
        btn: {
          confirm: '確認',
        }
      }
      return data
    })
    const planData = computed(() => {
      const data = get(props, 'plan')
      const contract = get(data, 'planContracts[0].contract.name') || '-'
      const contractId = get(data, 'planContracts[0].contract.id')
      const amount = get(data, 'installmentSettings.fields.amount')
      const periods = get(data, 'periodSettings.fields.periods')
      const days = get(data, 'periodSettings.fields.days')
      const paymentMethod = get(data, 'paymentTimeSettings.method')
      const downPaymentTime = get(data, 'paymentTimeSettings.fields.downPaymentTime')
      const periodMethod = get(data, 'periodSettings.method')

      let periodUnit = '個月'
      let displayPeriods = periods
      if (periodMethod === 'manual') {
        periodUnit = '天'
        displayPeriods = days * periods
      }

      return [{
        id: data.id,
        name: get(data, 'name'),
        items: [
          ...displayEquityPackContent.value,
          { label: '單次訂閱時長', value: `${displayPeriods} ${periodUnit}` },
          { label: '單次訂閱期數', value: periods ? `${periods} 期` : '-' },
          { label: '每次扣款金額', value: amount ? `${amount} 元` : '-' },
          { label: '扣款時機', value: `${get(subscribePaymentDependTypeConfig[paymentMethod], 'label')}，${get(subscribePaymentTimeTypeConfig[downPaymentTime], 'label')}` },
          { label: '訂閱合約', value: contract, link: { name: 'SubscribeContractEdit', params: { id: contractId }, query: { view: true } } },
        ],
      }]
    })

    const displayEquityPackContent = computed(() => {
      if (equityPack.type === 'custom') {
        const data = equityPack.data
        return [
          { label: '訂閱品項', value: get(data, 'name') },
          { label: '品項內容', value: join(getFormatEntitlementList(data), '\n') },
        ]
      } else if (equityPack.type === 'default') {
        const data = equityPack.data
        return [
          { label: '訂閱品項', value: get(data, 'name') },
          { label: '預估售價', value: `${get(data, 'price')} 元` },
          { label: '品項內容', value: get(data, 'contents') },
        ]
      }
      return [
        { label: '訂閱品項', value: '-' },
        { label: '品項內容', value: '-' },
      ]
    })

    const getPlanEquityPackData = async () => {
      const planEquityPack = get(props, 'plan.planEquityPack.equityPack')
      const equityMode = get(planEquityPack, 'mode')
      const isCustomEquityPack = get(planEquityPack, 'customOptions')
      const customEquityPackId = get(planEquityPack, 'customOptions.customEquityPackId')
      const customEquityPackType = get(planEquityPack, 'customOptions.customEquityPackType')
      equityPack.type = equityMode

      if (isCustomEquityPack) {
        if (customEquityPackType === 'periodicBenefitTemplate') {
          const [res, err] = await FindPeriodicBenefitTemplate({
            shopId: shopId.value,
            id: customEquityPackId,
          })
          if (err) {
            window.$message.error(err)
            return
          }
          equityPack.data = res
        }
      } else {
        // 兼容舊有的 "品項組合包" 資料
        const data = get(props, 'plan')
        const equities = get(data, 'planEquityPack.equityPack.equities')
        const equityContents = map(equities, item => get(item, 'name')).join('、')
        const sum = reduce(equities, (total, equity) => total + get(equity, 'price', 0), 0)
        equityPack.data = {
          name: get(data, 'planEquityPack.equityPack.name'),
          contents: equityContents,
          price: sum,
        }
      }
    }

    onBeforeMount(async () => {
      getPlanEquityPackData()
    })

    return {
      planData,
      displayText,
    }
  },
})
</script>

<style scoped lang="scss"></style>
